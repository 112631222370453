import React from 'react'
import Main from '../layouts/main'
import Hero from '../components/hero'

import styles from './contact.module.scss'

const Contact = () => (
  <Main>
    <Hero
      backgroundImage='contactBackground'
      backgroundOverlay={null}
    >
      <div className={`${styles.container} ${styles.heroLarge}`}>
        <div className={styles.hero}>
          <h1 className={`${styles.fadeInOne} ${styles.titleHero}`}>
            LET’S MAKE
          </h1>
          <hr className={styles.divider}/>
          <h1 className={`${styles.fadeInTwo} ${styles.titleHero}`}>
            CONTACT
          </h1>
        </div>
      </div>
    </Hero>
    <div className={styles.main}>
      <div className={styles.contactDiv}>
        <p className={styles.contactIntro}>
          Launching a new product? Need help on an existing project?
        </p>
        <h2 className={styles.contactTitle}>
          We’d love to talk.
        </h2>
      </div>
      <form className={styles.contactForm} action="https://formspree.io/f/mknkqjjj" method="POST">
        <div className={`${styles.asteriskCell} ${styles.reqs}`}>
          <span className={styles.asterisk}>*</span>
          required fields
        </div>
        <div className={styles.name}>
          <div className={styles.formField}>
            <label>
              Name
              <span className={styles.asterisk}>*</span>
              <input name="name" required/>
            </label>
          </div>
        </div>
        <div className={styles.email}>
          <div className={styles.formField}>
            <label>
              Email
              <span className={styles.asterisk}>*</span>
              <input name="_replyto" type="email" required/>
            </label>
          </div>
        </div>
        <div className={styles.companyName}>
          <div className={styles.formField}>
            <label>
              Company Name
              <span className={styles.asterisk}>*</span>
              <input name="company_name" required/>
            </label>
          </div>
        </div>
        <div className={styles.website}>
          <div className={styles.formField}>
            <label>
              Website
              <input name="website"/>
            </label>
          </div>
        </div>
        <div className={styles.howDidYouHear}>
          <div className={styles.formField}>
            <label>
              How did you hear about us?
              <input name="how_did_you_here"/>
            </label>
          </div>
        </div>
        <div className={styles.howCanWeHelp}>
          <div className={styles.formField}>
            <label>
              How can we help you?
              <textarea name="how_can_we_help"/>
            </label>
          </div>
        </div>
        <div className={styles.submit}>
          <div className={styles.formField}>
            <button
              className={`${styles.button} ${styles.buttonMargin}`}
              type="submit"
              value="Submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div className={styles.map}>
      <div className={styles.mapImage}/>
    </div>
  </Main>
)

export default Contact
